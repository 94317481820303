import { getOpeningHoursForDate } from '~/utils/opening-hours'
import type { OpeningHours } from '~/types/api'

function stringTimeToFloat(time: string): number {
    const [hours, minutes] = time.split(':')
    return parseInt(hours) + parseInt(minutes) / 60
}

/*
 * Times with all half hours between openingHours.
 * Only allow 00 and 30 minutes. If startTime is not a round number or half number, round it to the nearest half hour.
 */
export function getDateAvailableTimes(date: Date, openingHoursSpecification: OpeningHours[], minDepartureDate: Date) {
    const openingHours = getOpeningHoursForDate(date, openingHoursSpecification)
    const result = [] as Date[]

    for (const openingHour of openingHours) {
        let startTime = stringTimeToFloat(openingHour.opens)
        const endTime = stringTimeToFloat(openingHour.closes)

        // If startTime is not a round number or half number, round it to the nearest half hour
        // 9:01 -> 9:30
        // 9:29 -> 9:30
        // 9:30 -> 9:30
        // 9:31 -> 10:00
        // 9:59 -> 10:00
        if (startTime % 0.5 !== 0) {
            startTime
                = Math.floor(startTime) >= Math.round(startTime) ? Math.floor(startTime) + 0.5 : Math.round(startTime)
        }

        for (let i = startTime; i < endTime; i += 0.5) {
            const timeDate = new Date(date)

            if (i % 1 === 0) {
                timeDate.setHours(i)
                timeDate.setMinutes(0)
            }
            else {
                timeDate.setHours(Math.floor(i))
                timeDate.setMinutes(30)
            }

            result.push(timeDate)
        }
    }

    return result.filter(timeDate => timeDate > minDepartureDate)
}
