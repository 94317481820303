<script setup lang="ts">
import type { DatePickerInstance } from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { max } from 'date-fns'
import type { DayOfWeek, OpeningHours } from '~/types/api'
import { formatLocalDateOnly, weekDays } from '~/utils/opening-hours'
import type { ReservationAgency } from '~/components/organisms/VReservationForm/VReservationForm.vue'
import { getDateAvailableTimes } from '~/utils/reservation/get-date-available-times'

interface Props {
    mode?: 'start' | 'end'
    agency?: ReservationAgency
    modelValue: Date[]
    maxRange?: number
    disabled?: boolean
    title?: string
}

const props = withDefaults(defineProps<Props>(), {
    disabled: false,
    maxRange: 20,
})

const emit = defineEmits(['update:modelValue'])

const { t, d } = useI18n()

// DATE PICKER COMPONENT
const datePickerComponent = defineAsyncComponent(() => import('@vuepic/vue-datepicker'))
const datePicker = ref<DatePickerInstance>()

// DATE RANGE
const dateRange = computed({
    get() {
        return props.modelValue || []
    },
    set(value: Array<Date | null> | null) {
        emit('update:modelValue', value || [])
    },
})

// close date picker when dateRange is updated
watch(dateRange, () => {
    datePicker.value?.closeMenu()
})

// DATE
const startDate = computed(() => dateRange.value?.[0])

const activeDate = computed(() => {
    return props.mode === 'start' ? dateRange.value?.[0] : dateRange.value?.[1]
})

// MIN DEPARTURE DATE
const minDepartureHoursDelay = computed(() => props.agency?.minimumDepartureHoursDelay || 0)

const minDepartureDate = computed<Date>(() => {
    const date = new Date()

    // add the minimum departure hours delay
    date.setHours(date.getHours() + minDepartureHoursDelay.value)

    return date
})

const minDate = computed<Date>(() => {
    const date = new Date(minDepartureDate.value)

    // reset hours / minutes / seconds / milliseconds because the calendar day comparisons are based on the date at 00:00:00
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)

    return date
})

// MAX DEPARTURE DATE
const maxDepartureDaysDelay = computed(() => props.agency?.maximumDepartureDaysDelay || 0)

const maxDepartureDate = computed(() => {
    const date = new Date()

    date.setDate(date.getDate() + maxDepartureDaysDelay.value)

    return date
})

// MIN RETURN DATE
// const minReturnHoursDelay = computed(() => props.agency?.minimumReturnHoursDelay || 0)
//
// const minReturnDate = computed(() => {
//     const date = startDate.value ? new Date(startDate.value) : new Date()
//
//     date.setHours(date.getHours() + minReturnHoursDelay.value)
//
//     return date
// })

// MAX RETURN DATE
const maxReturnDaysDelay = computed(() => props.agency?.maximumReturnDaysDelay || 0)

const maxReturnDate = computed(() => {
    const date = startDate.value ? new Date(startDate.value) : new Date()

    date.setDate(date.getDate() + maxReturnDaysDelay.value)

    return date
})

// RESPONSIVE
const isMobile = ref(false)

let resizeDebounce = 0

function updateIsMobile() {
    isMobile.value = matchMedia('(max-width: 767px)').matches
}

function onResize() {
    if (resizeDebounce) {
        window.clearTimeout(resizeDebounce)
        resizeDebounce = 0
    }

    resizeDebounce = window.setTimeout(() => {
        isMobile.value = matchMedia('(max-width: 767px)').matches
    }, 300)
}

onMounted(() => {
    updateIsMobile()
    window.addEventListener('resize', onResize)
})

onBeforeUnmount(() => {
    window.removeEventListener('resize', onResize)
})

// OPEN STATE
const opened = ref(false)

const onOpen = () => {
    opened.value = true
}

const onClosed = () => {
    opened.value = false
}

// https://developers.google.com/search/docs/appearance/structured-data/local-business?hl=fr#all-day-hours
const openedSpecificOpeningHours = computed(() => {
    return openingHoursSpecification.value.filter(
        (openingHours: OpeningHours) =>
            openingHours.validFrom !== undefined
            && openingHours.validThrough !== undefined
            && (openingHours.opens !== '00:00' || openingHours.closes !== '00:00')
            && (openingHours.opens !== '00:00:00' || openingHours.closes !== '00:00:00'),
    )
})

const closedSpecificOpeningHours = computed(() => {
    return openingHoursSpecification.value.filter(
        (openingHours: OpeningHours) =>
            openingHours.validFrom !== undefined
            && openingHours.validThrough !== undefined
            && ((openingHours.opens === '00:00' && openingHours.closes === '00:00')
                || (openingHours.opens === '00:00:00' && openingHours.closes === '00:00:00')),
    )
})

const openingHoursSpecification = computed(() => {
    return props.agency?.openingHoursSpecification || []
})

const disabledWeekDays = computed(() => {
    const enabledWeekDays = [] as number[]
    const allWeekDays = [0, 1, 2, 3, 4, 5, 6] as number[]
    // read openingHoursSpecification and return disabled week days
    const openingHoursWithWeekDays = openingHoursSpecification.value.filter(
        (openingHours: OpeningHours) =>
            openingHours.dayOfWeek !== undefined && (openingHours.opens !== '00:00' || openingHours.closes !== '00:00'),
    )
    for (const openingHours of openingHoursWithWeekDays) {
        for (const allowedWeekDay in weekDays) {
            if (
                openingHours.dayOfWeek === (allowedWeekDay as DayOfWeek)
                || (Array.isArray(openingHours.dayOfWeek) && openingHours.dayOfWeek.includes(allowedWeekDay as DayOfWeek))
            ) {
                enabledWeekDays.push(weekDays[allowedWeekDay as DayOfWeek])
            }
        }
    }
    return allWeekDays.filter(weekDay => !enabledWeekDays.includes(weekDay))
})

const additionalAllowedDates = computed(() => {
    const allowedDates = [] as string[]
    for (const openingHours of openedSpecificOpeningHours.value) {
        const validFromDate = new Date(openingHours.validFrom as string)
        const validThroughDate = new Date(openingHours.validThrough as string)
        const currentDate = new Date(validFromDate)

        while (currentDate <= validThroughDate) {
            const datePart = formatLocalDateOnly(currentDate)
            if (!allowedDates.includes(datePart)) {
                allowedDates.push(datePart)
            }
            currentDate.setDate(currentDate.getDate() + 1)
        }
    }
    return allowedDates
})

const additionalDisallowedDates = computed(() => {
    const disallowedDates = [] as string[]
    for (const openingHours of closedSpecificOpeningHours.value) {
        const validFromDate = new Date(openingHours.validFrom as string)
        const validThroughDate = new Date(openingHours.validThrough as string)
        const currentDate = new Date(validFromDate)

        while (currentDate <= validThroughDate) {
            const datePart = formatLocalDateOnly(currentDate)
            if (!disallowedDates.includes(datePart)) {
                disallowedDates.push(datePart)
            }
            currentDate.setDate(currentDate.getDate() + 1)
        }
    }
    return disallowedDates
})

const isDateDisabled = (date: Date | string | null | undefined): boolean => {
    if (!date) {
        return false
    }

    if (typeof date === 'string') {
        date = new Date(date)
    }

    const datePart = formatLocalDateOnly(date)

    // Disable all days before today
    if (date < new Date()) {
        return true
    }

    // Disable all days before minDate
    if (minDate.value && date < minDate.value) {
        return true
    }

    // Disable all days that are not allowed
    if (additionalDisallowedDates.value.includes(datePart)) {
        return true
    }

    // Departure
    if (props.mode === 'start') {
        // if (endDate.value && date > endDate.value) return true
        if (maxDepartureDate.value && date > maxDepartureDate.value) return true
    }

    // Return
    if (props.mode === 'end') {
        // if (startDate.value && date < startDate.value) return true
        // if (startDate.value && date < startDate.value) return true
        // if (maxReturnDate.value && date > maxReturnDate.value) return true
        if (!startDate.value && date > maxDepartureDate.value) return true
    }

    // Even if the date is greater than the min date, we need to check if there are available times for this date.
    // For example, if the current time is after the closing time of the agency,
    // then the next available time will be tomorrow (current time + min departure hours delay) but the agency will be closed.
    // So we need to disable the date.
    const availableTimes = getDateAvailableTimes(date, openingHoursSpecification.value, minDepartureDate.value)

    if (!availableTimes.length) {
        return true
    }

    // Enable all days that are allowed
    if (additionalAllowedDates.value.includes(datePart)) {
        return false
    }

    // Disable all days that are not allowed
    return disabledWeekDays.value.includes(date.getDay())
}

function onDateUpdate(value: Date) {
    const range = [...(dateRange.value || [])]

    range[props.mode === 'start' ? 0 : 1] = value

    dateRange.value = range
}

// https://vue3datepicker.com/props/general-configuration/#highlight
const highlightDates = computed(() => {
    if (!dateRange.value?.[0] || !dateRange.value?.[1]) return null

    return (date: Date) => date > (dateRange.value?.[0] as Date) && date < (dateRange.value?.[1] as Date)
})

const formatDates = () => {
    if (!activeDate.value) return

    return d(activeDate.value)
}

const maxDate = computed(() => max([maxDepartureDate.value, maxReturnDate.value]))

const placeholder = computed(() => {
    return t(
        props.mode === 'start'
            ? 'rent_a_car.departure.date_picker.placeholder'
            : 'rent_a_car.return.date_picker.placeholder',
    )
})

const dialogTitle = computed(() => {
    return t(
        props.mode === 'start'
            ? 'rent_a_car.departure.date_picker.dialog.title'
            : 'rent_a_car.return.date_picker.dialog.title',
    )
})

function onKeyDown(event: KeyboardEvent) {
    switch (event.key) {
        case ' ':
        case 'ArrowUp':
        case 'ArrowDown':
            event.preventDefault()
            datePicker.value?.openMenu()
            break
    }
}

const $style = useCssModule()

const calendarClassName = computed(() => {
    return dateRange.value?.[0] && dateRange.value?.[1] && dateRange.value[0] < dateRange.value[1]
        ? $style['calendar--filled']
        : ''
})
</script>

<template>
    <component
        :is="datePickerComponent"
        ref="datePicker"
        v-model="dateRange"
        :class="[
            $style.root,
            $style['root--size-xl'],
            opened && $style['root--opened'],
            disabled && $style['root--disabled'],
        ]"
        :calendar-class-name="calendarClassName"
        :multi-calendars="!isMobile"
        :teleport="true"
        teleport-center
        multi-dates
        :highlight="highlightDates"
        required
        auto-apply
        partial-flow
        :disabled="disabled"
        :placeholder="placeholder"
        prevent-min-max-navigation
        timezone="Europe/Paris"
        :disabled-dates="isDateDisabled"
        month-name-format="long"
        :min-date="minDate"
        :max-date="maxDate"
        locale="fr-FR"
        :enable-time-picker="false"
        :format="formatDates"
        :clearable="false"
        @open="onOpen"
        @closed="onClosed"
        @date-update="onDateUpdate"
        @keydown="onKeyDown"
    >
        <template #left-sidebar>
            <div :class="$style['dialog__header']">
                <p :class="$style['dialog__title']">
                    {{ dialogTitle }}
                </p>
                <VButton
                    :class="$style['dialog__close']"
                    :aria-label="t('close')"
                    outlined
                    size="s"
                    icon-name="close-small"
                    @click.prevent="datePicker?.closeMenu()"
                />
            </div>
        </template>
        <template #input-icon>
            <SvgIcon
                name="calendar"
                :class="$style.icon"
                viewBox="0 0 24 24"
                width="24"
                height="24"
            />
        </template>
    </component>
    <ClientOnly>
        <Teleport to="body">
            <div
                v-if="opened"
                :class="$style.backdrop"
            />
        </Teleport>
    </ClientOnly>
</template>

<style lang="scss">
.dp--menu-wrapper {
    --dp-font-family: var(--font-family);
    --dp-font-size: 14px;
    --dp-animation-duration: 0s; // deactivate appearance animation (transition prop cannot deactivate only the appearance animation)
    --dp-cell-border-radius: 4px;
    --dp-row-margin: 8px 0;
    --dp-cell-margin-inline: 2px;
    --dp-cell-size: 32px;
    --dp-disabled-color: var(--color-primary-disabled);

    position: relative;

    @include media('<md') {
        top: initial !important;
        bottom: 0 !important;
        left: 0 !important;
        width: 100%;
        transform: initial !important;
    }
}

.dp__menu {
    border-radius: var(--radius-md) var(--radius-md) 0 0;

    @include media('>=md') {
        border-radius: var(--radius-md);
    }
}

.dp__theme_light {
    --dp-primary-color: var(--color-primary);
    --dp-border-color-hover: var(--color-black);
    --dp-disabled-color: var(--color-primary-disabled);
    --dp-border-color: var(--color-line-primary);
    --dp-text-color: var(--color-primary);
    --dp-icon-color: var(--color-primary);
}

.dp__menu_content_wrapper {
    display: flex;
    flex-direction: column;
}

.dp__menu_inner {
    width: min-content;
    padding: rem(24) rem(48);
    gap: var(--spacing-2-xs);
    margin-inline: auto;

    @include media('>=md') {
        padding: var(--spacing-sm) var(--spacing-md);
    }

    @include media('>=vl') {
        padding: var(--spacing-sm) var(--spacing-3-xl, 64px) rem(40);
        gap: rem(40);
    }
}

.dp__instance_calendar {
    width: 100%;
}

.dp__month_year_wrap {
    justify-content: center;
    pointer-events: none;

    &:first-child {
        margin-left: rem(25);
    }

    &:last-child {
        margin-right: rem(25);
    }
}

.dp__month_year_row {
    margin-bottom: var(--spacing-2-xs);
}

.dp__month_year_select {
    width: initial;
    font-weight: 700;
    padding-inline: rem(8);
}

.dp__calendar_header_item {
    font-size: 12px;
    text-transform: uppercase;
}

.dp__calendar_header_separator {
    display: none;
}

.dp__input {
    position: relative;
    height: var(--input-height, 48px);
    font-weight: 500;

    &:disabled {
        --dp-border-color: rgb(159 159 159 / 70%);

        color: var(--color-primary-disabled);
        opacity: 1; // select has 0.7 opacity when disabled
    }

    &:focus,
    &:hover {
        z-index: 2;
    }

    &::placeholder {
        color: var(--color-content-secondary);
        font-weight: 400;
        opacity: 1;
    }

    &:disabled::placeholder {
        color: var(--color-primary-disabled);
    }
}

.dp__input_icon {
    z-index: 2;
    height: 24px;
}

.dp__disabled {
    background-color: var(--color-white);
}

.dp__calendar_item {
    position: relative;
    z-index: 0;
    padding-inline: var(--dp-cell-margin-inline);

    &:has(.dp__cell_highlight) {
        background-color: var(--color-surfaces-tertiary);
    }
}

.dp__calendar_item:has(.dp__cell_highlight) + .dp__calendar_item[aria-selected='true'],
.dp__calendar_item[aria-selected='true'] + .dp__calendar_item[aria-selected='true'] {
    &::before {
        right: auto;
        left: 0;
    }
}

.dp__cell_inner {
    width: var(--dp-cell-size);
    height: var(--dp-cell-size);
    border-radius: var(--dp-cell-border-radius);
}

.dp__cell_inner:hover:not(.dp__cell_disabled, .dp__active_date) {
    border-color: var(--color-primary);
    background: none;
    transition: none;
}

.dp__cell_highlight {
    position: relative;
    z-index: 1;
    background-color: transparent;
}

.dp__action_row {
    display: flex;
    justify-content: flex-end;
    padding: 0 var(--spacing-md) var(--spacing-md);

    @include media('>=vl') {
        padding: 0 var(--spacing-xl) var(--spacing-md);
    }
}

.dp__sidebar_left {
    padding: 0;
    border-inline-end: 0 none;
}

.dp__inner_nav {
    &_disabled {
        background: none;
        color: var(--color-primary-disabled);
    }

    @media (hover: hover) {
        &:hover {
            background: none;
        }

        &:hover:not(.dp__inner_nav_disabled) {
            color: var(--color-primary-hover);
        }
    }
}

.dp__today {
    border-color: var(--dp-secondary-color);
}
</style>

<style lang="scss" module>
.backdrop {
    position: fixed;
    z-index: 99998;
    display: block;
    background-color: rgb(0 0 0 / 60%);
    content: '';
    inset: 0;
}

.root {
    --dp-border-radius: var(--radius-sm, 8px) 0 0 var(--radius-sm, 8px);
    --dp-font-family: var(--font-family);
    --dp-font-size: 14px;
    --dp-input-padding: var(--spacing-2-xs) var(--spacing-xs);
    --dp-input-icon-padding: calc(var(--spacing-2-xs) + 28px);
    --dp-border-color: var(--color-border-primary);
    --dp-border-color-hover: var(--color-black);

    &--disabled {
        border: none;
        pointer-events: none;

        //border: 1px solid var(--color-primary-disabled);
        //--dp-border-color: var(--color-primary-disabled);
    }
}

.calendar--filled :global(.dp__calendar_item[aria-selected='true']) {
    &::before {
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        width: calc(var(--dp-cell-margin-inline) + var(--dp-cell-margin-inline));
        height: 100%;
        background-color: var(--color-surfaces-tertiary);
        content: '';
    }
}

.dialog__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(24) rem(24) rem(20);
    border-bottom: 1px solid var(--color-line-primary, rgb(1 1 1 / 10%));
    gap: rem(12);

    @include media('>=md') {
        padding: rem(30) rem(30) rem(26) rem(48);
    }
}

.dialog__title {
    @include text-h2;

    padding-right: rem(20);
    margin: 0;
    font-style: italic;
    font-weight: 900;
    letter-spacing: 0.36px;
    text-transform: uppercase;
}

.dialog__agencies {
    margin-left: auto;
}

.dialog__close {
    height: 32px;
    border: 1px solid var(--color-line-primary, rgb(1 1 1 / 15%));
}

.hours {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--color-line-primary);
    gap: var(--spacing-2-xs);
    padding-block: var(--spacing-sm);
    padding-inline: var(--spacing-sm);

    @include media('>=md') {
        flex-direction: row;
        padding: var(--spacing-sm) var(--spacing-md);
    }

    @include media('>=vl') {
        padding: var(--spacing-sm) var(--spacing-xl);
    }
}

.hours-input {
    flex-grow: 1;
    margin: 0;
}

.hours-select {
    min-width: 100px;
}

.icon {
    width: 24px;
    height: 24px;
    margin-left: var(--spacing-2-xs);
    color: var(--color-content-secondary);

    .root--disabled & {
        color: var(--color-primary-disabled);
    }
}
</style>
